@use "../../Assets/Styles/Utility/variables" as *;

.areas {
    padding: $padding;
    &__heading {
        color: $primary; 
        margin-bottom: 2rem;
    }
    &__main {
        display: grid;
        grid-template-areas: 1fr;
        &-buttons {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            width: 100%;
            margin-bottom: 1rem;
            li {
                list-style-type: none;
            }
        }
        &-content {
            &-para {
                width: 100%;
                margin: 1rem 0;
                display: inline-block;
            }
        } 
    } 
}

@media screen and (min-width: 768px) {
    .areas {
        &__main {
            grid-template-columns: 1fr 1fr 1fr;
            align-items: center;
            min-height: 400px;
            &-buttons {
                width: 20vw;
                margin-bottom: 0;
                grid-row: 1;
                grid-column: 3;
                justify-self: flex-end;
            }
            &-content {
                grid-row: 1;
                grid-column: 1 / span 2;
                text-align: left;
                // width: 30vw;
            }
            &-img {
                grid-row: 2;
                grid-column: 1 / span 3;
            }
        }
    }
}


@media screen and (min-width: 1024px) {
    .areas {
        &__main {
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            column-gap: 3rem;
            &-buttons {
                grid-row: 2;
                grid-column: 1;
                width: 100%;
            }
            &-content {
                text-align: left;
                grid-row: 1;
                grid-column: 1;
                width: 100%;
            }
            &-img {
                grid-row: 1 / span 2;
                grid-column: 2 / span 2;
                margin-top: 0;
                width: 100%;
            }
        }
    }
}

@media screen and (min-width: 1440px) {
    .areas {
        margin: 2rem 10% 2rem 10%;
    }
}