$dark: #33383D;
$light: #FFF;

$primary: #570D2A;
$secondary: #FFF;

$text-light: #929597;

$padding: 4rem 10%;
$padding-responsive: 4rem 15%;

// .container {
//     padding: 2rem 10%;
// }

// @media screen and (min-width: 1024px) {
//     .container {
//         padding: 2rem 15%;
//     }
// }