@use "../../Assets/Styles/Utility/variables" as *;

.google-map {
    width: 100%;
    height: 40vh;
    .google-map__pin-icon {
        color: $primary;
    }
    .google-map__pin-text {
        font-size: 1em;
        font-weight: 800;
        color: $primary;
        width: 100px;
    }
}


