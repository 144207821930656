@use "../../Assets/Styles/Utility/variables" as *;

.home-hero {
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    padding: $padding;
    &__img {
        width: 100%;
        height: 30vh;
        object-fit: cover;
    }
    &__content {
        margin-bottom: 1rem;
        &-title {
            color: $secondary;
            margin-bottom: 1rem;
        }

        &-subtitle {
            color: $text-light;
            margin-bottom: 1rem;
        }
    }
}

.home-news {
    background-color: $dark;
    padding: $padding;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    &__article {
        background-color: $light;
        border: none;
        height: 100%;
        &-title {
            color: $dark;
            padding: 2rem 2rem 1rem 2rem;
        }
        &-img {
            width: 100%;
            height: 30vh;
            object-fit: cover;
        }
        &-para {
            padding: 1rem 2rem;
        }
        &-btn {
            padding: 0 2rem 2rem 2rem;
        }
    }
}

@media screen and (min-width: 768px) {
    .home-hero {
        &__content {
            &-subtitle {
                width: 60vw;
            }
        }
    }
    .home-news {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (min-width: 1024px) {
    .home-news {
        grid-template-columns: 1fr 1fr;
    }
    .home-hero {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        column-gap: 4rem;
        &__content {
            margin-bottom: 0;
            &-subtitle {
                width: 20vw;
            }
        }
        &__img {
            height: 50vh;
        }
    }
    .home-reviews {
        margin-bottom: 3rem;
    }
}