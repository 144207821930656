html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}