@use "../../Assets/Styles/Utility/variables" as *;

.button-primary {
    background-color: $secondary;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    color: $dark;
    cursor: pointer;
}

.button-secondary {
    background-color: $primary;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    color: $light;
    cursor: pointer;
}

.button-tertiary {
    background-color: $dark;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    color: $light;
    cursor: pointer;
}

.button-menu {
    background-color: $dark;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    color: $light;
    cursor: pointer;
}

.button-menu--active {
    background-color: $primary;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    color: $light;
    cursor: pointer;
}

.button-simple {
    border: none;
    background-color: transparent;
    color: black;
    text-decoration: underline;
}

.button-area {
    width:100%;
    color: $dark;
    border: 2px solid $dark;
    background-color: $light;
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.button-area--active {
    background-color: $dark;
    border: 2px solid $dark;
    padding: 0.5rem 1rem;
    width: 100%;
    color: $light;
    cursor: pointer;
}

.button-menu-sub {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    background-color: $light;
    //border: 2px solid $dark;
    text-align: left;
    border-radius: 5px;
    color: $dark;
    text-decoration:underline;
    font-weight: 800;
}

.button-form {
    background-color: $dark;
    color: $light;
    border: none;
}

@media screen and (min-width: 768px) {
    .button-menu-sub {
        text-align: center;
    }
}

@media screen and (min-width: 1024px) {
    .button-menu {
        &__text {
            font-size: 20px;
        }
    }
}