@use "../../Assets/Styles/Utility/variables" as *;

.menu-hero {
    &__subheader {
        margin-bottom: 1rem;
    }
    
    &__buttons {
        display: flex;
        flex-direction: column;
        row-gap: 1.25rem;
    }
}
.menu {
    padding: 1rem 0;
    color: $light;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    &-header {
        border-bottom: 1px solid $light;
        font-family: 200;
        padding-bottom: 1rem;
        font-size: 24px;
        width: 100%;
        margin-top: 1rem;
    }

    &-section {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;

        &-item {
            display: flex;
            flex-direction: column;
            row-gap: 0.5rem;
            &-header {
                display: flex;
                justify-content: space-between;
                font-size: 18px;
                color: $light
            }
        }
    }
}


@media screen and (min-width: 768px) {
    .menu-hero {
        &__buttons {
            flex-direction: row;
            justify-content: space-between;
            column-gap: 1rem;
            &-button {
                flex: 1
            }
        }
    }
    
    .menu {
        &-section {
            grid-template-columns: 1fr 1fr;
        }
    }
}

@media screen and (min-width: 1024px) {
    .menu-hero {
        &__buttons {
            flex-direction: row;
            justify-content: space-between;
            column-gap: 1rem;
            &-button {
                flex: 1;
                padding: 15px; 
            }
        }
    }
    
    .menu {
        &-section {
            grid-template-columns: 1fr 1fr 1fr;
            gap: 3rem;
        }
    }
}