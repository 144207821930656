@use "../../Assets/Styles/Utility/variables" as *;

.carousel {
    position: relative;
    &__img {
        width: 100%;
        height: 20vh;
        object-fit: cover;
        margin-top: 1rem;
    }
    &__counter {
        position: absolute;
        bottom: 8px;
        right: 50%;
        transform: translate(50%, 0);
        color: $dark;
        font-weight: 400;
        background-color: $light;
        padding: 5px 10px;
        border-radius: 1rem;
    }
    &__button {
        border: none;
        background-color: transparent;
        color: $light;
        cursor: pointer;
    }
    &__button--next {
        position: absolute;
        top: 50%;
        right: 5px;
    }
    &__button--prev {
        position: absolute;
        top: 50%;
        left: 5px;
    }
}

@media screen and (min-width: 768px) {
    .carousel {
        &__img {
            height: 40vh;
        }
    }
}

@media screen and (min-width: 1024px) {
    .carousel {
        &__img {
            height: 30vh;
        }
    }
}