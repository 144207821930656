@use "../../Assets/Styles/Utility/variables" as *;

.reviews {
    margin: 2rem 0;
    &__header {
        margin: 4rem 10% 2rem 10%;
        h2 {
            margin-bottom: 1rem;
        }
        h3 {
            margin-bottom: 0.5rem;
        }
        p {
            margin-bottom: 0.5rem;
        }
        .reviews__buttons {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 2rem;
        }
    }
    &__review {
        background-color: $dark;
        padding: 2rem 10%;
        .reviews__name {
            margin-bottom: 0.5rem;
        }
    }
}

@media screen and (min-width: 1024px){
    .reviews {
        display: grid;
        grid-template-columns: 1fr 1.5fr;
        grid-template-rows: 1fr;
        grid-column-gap: 50px;
        grid-row-gap: 0px;
        align-items: center;
        margin: 2rem 10% 0 10%;
        
        &__review {
            padding: 2rem;
            margin: 0;
            height: fit-content;
        }
    }
}