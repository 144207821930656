@use "./variables" as *;
@import url('https://fonts.googleapis.com/css2?family=Readex+Pro&display=swap');


h1 {
    font-size: 18px;
    font-family: 'Readex Pro', sans-serif;
}
h1.primary {
    color: $primary;
}

h3 {
    color: $dark;
    font-size: 30px;
    font-family: 'Readex Pro', sans-serif;
    font-weight: bold;
}

h2 {
    font-size: 18px;
    font-family: 'Readex Pro', sans-serif;
}
h2.primary {
    color: $primary;
}

h2.secondary {
    color: $secondary;
}

h2.tertiary {
    color: $light;
}

p {
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
    color: $text-light;
}

a, button {
    font-size: 18px;
}


.italics {
    font-style: italic;
}

.highlight {
    color: $secondary;
}

.icon-facebook, .icon-facebook:visited {
    color: #3b5998;
}