@use "../../Assets/Styles/Utility/variables" as *;

.contact-hero {
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.about {
    &-useful {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        &-parking {
            &__header {
                color: $secondary;
                margin-bottom: 1rem;
            }
            &__text {
                margin-bottom: 0.5rem;
            }
        }
        &-info {
            align-self: flex-end;
            text-align: right;
            &__header {
                color: $secondary;
                margin-bottom: 1rem;
            }
            &__text {
                margin-bottom: 0.5rem;
            }
        }
    }
    &-info {
        background-color: $dark;
        display: flex;
        justify-content: space-between;
        &__address {

        }
        &__contact {
            text-align: right;
            a {
                color: $text-light;
                display: block;
            }
        }
    }
}

.contact {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    &__info {
        display: flex;
        justify-content: space-between;
        padding: $padding;
        margin-top: 2rem;
        margin-right: 10%;
        align-items: center;
        width: 100%;
        .contact__info-header {
            color: $secondary;
            margin-bottom: 1rem;
        }
        &-text{
            &-links{
                color: $text-light;
            }
            &-links:hover {
                color: $light;
            }
            &-times {
                color: $text-light;
                ul {
                    list-style-type: none;
                }
            }
        }
        &-links {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            row-gap: 1rem;
            img {
                height: 55px;
                width: 55px;
            }
            &-ta, &-fb {
                color: $secondary;
            }
            &-ta:hover {
                color: #00af87;
            }
            &-fb:hover {
                color: #1877f2;
            }
        }
    }
    &__form{
        padding: $padding;
        display: flex;
        flex-direction: column;
        row-gap: 0.75rem;
        width: 100%;
        input, textarea, button {
            padding: 0.5rem;
            width: 100%;
            border-radius: 5px;
            border: 2px solid $dark;
        }
        &-success {
            color: green;
            text-align: center;
        }
    }
}

@media screen and (min-width: 768px) {
    .contact {
        &__info{
            &-links {
                column-gap: 2rem;
            }
        }
    }
}


@media screen and (min-width: 1024px) {
    .contact {
        flex-direction: row-reverse;
        column-gap: 1rem;
        align-items: stretch;
        &__info{
            flex: 1;
            margin-top: 0;
            width: 40vw;
            column-gap: 2rem;
            padding: 2rem;
            &-links {
            }
            &-text {
                
            }
        }
        &__form {
            flex: 2;
        }
    }
}