@use "./variables" as *;

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
}

a, a:link, a:visited {
    color: $text-light;
}

main {
    background-color: $light;
}

.container {
    padding: 2rem 10%;
}

.dark {
    background-color: $dark;
}

@media screen and (min-width: 1440px) {
    .container {
        padding: 2rem 20%;
    }
}