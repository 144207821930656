@use "../../Assets/Styles/Utility/variables" as *;

.nav {
    img {
        width: 150px;
    }
    .nav__main {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: space-between;
    }
    button {
        font-size: 30px;
        margin-right: 20px;
        color: white;
        background: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        float: right;
    }
    li {
        padding: 10px 0;
        a {
            text-decoration: none;
            font-weight: 100;
            padding: 0 15px;
        }
        .hover:hover {
            color: $light;
        }
        .active {
            color: $light;
        }
    }

    &__links {
        height: 0;
        overflow: hidden;
        list-style-type: none;
        font-weight: bold;
    }
    .show-nav {
        height: auto;
    }
}

@media screen and (min-width: 768px) {
    .nav {
        padding: 0 5%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
            display: none;
        }
    
        &__links {
            height: auto;
            display: flex;
        }
    
        li {
            padding: 0 15px;
        }
        .show-nav {
            height:auto;
        }
    }
}